@tailwind base;
@tailwind components;
@tailwind utilities;

.world-rally-blue {
  background-color: #002C71;
}

.null-sec-status {
  color: #8d3264;
}
.low-sec-0-1 {
  color: #722020;
}
.low-sec-0-2 {
  color: #bc1117;
}
.low-sec-0-3 {
  color: #ce440f;
}
.low-sec-0-4 {
  color: #DC6D07;
}
.high-sec-0-5 {
  color: #f3fd82;
}
.high-sec-0-6 {
  color: #71e554;
}
.high-sec-0-7 {
  color: #60d9a3;
}
.high-sec-0-8 {
  color: #4ecef8;
}
.high-sec-0-9 {
  color: #3a9aeb;
}
